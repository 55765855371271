import { sortByOrder, sortByProperty } from '@/helpers/sort';
import { executiveOfficersOrder } from '@/helpers/officials';

export const gridDefaultColDef = {
  resizable: true,
  sortable: true,
};

export function gridColumns(ProfileLink) {
  return [
    {
      headerName: 'Full Name',
      flex: 1,
      sort: 'asc',
      sortIndex: 2,
      sortable: false,
      valueGetter: (params) => params.data,
      cellRenderer: ProfileLink,
      comparator: (valueA, valueB) => sortByProperty(valueA, valueB, 'surname'),
    },
    {
      headerName: 'Country/Region',
      field: 'country',
      sortable: false,
      sortIndex: 1,
      sort: 'asc',
      flex: 1,
    },
    {
      headerName: 'Role',
      flex: 1,
      sortable: false,
      sort: 'asc',
      sortIndex: 0,
      autoHeight: true,
      valueGetter: (params) => params.data.positions[0].name,
      cellRenderer: (params) => {
        const { positions } = params.data;

        return positions
          .map((p) => `<div>${p.name}</div>`)
          .join('');
      },
      comparator: (valueA, valueB) => sortByOrder(valueA, valueB, executiveOfficersOrder),
    },
  ];
}
